import { addPropertyControls, ControlType } from "framer"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Icon(props) {
    const sizeMap = {
        xxs: 12,
        xs: 16,
        sm: 18,
        base: 24,
        xl: 40,
        "2xl": 64,
        mega: 96,
    }

    const iconSize = sizeMap[props.size] || sizeMap.base
    const icons = {
        add: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M12 4v8m0 0v8m0-8H4m8 0h8"
                />
            </svg>
        ),
        "api-connection": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill={props.borderColor}
                    d="M2 11H1v2h1zm20 2h1v-2h-1zm-10 6a7 7 0 0 1-7-7H3a9 9 0 0 0 9 9zm-7-7a7 7 0 0 1 7-7V3a9 9 0 0 0-9 9zm10 0a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5zm-3 3a3 3 0 0 1-3-3H7a5 5 0 0 0 5 5zm-3-3a3 3 0 0 1 3-3V7a5 5 0 0 0-5 5zm3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5zm-8 2H2v2h2zm8-6a7 7 0 0 1 6.491 4.375l1.854-.75A9 9 0 0 0 12 3zm6.563 9.441A7 7 0 0 1 12 19v2a9 9 0 0 0 8.437-5.861zM16 13h6v-2h-6z"
                />
            </svg>
        ),
        "arrow-right": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="m14 6 6 6-6 6m5-6H4"
                />
            </svg>
        ),
        "arrow-left": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="m10 6-6 6 6 6m-5-6h15"
                />
            </svg>
        ),
        "arrow-right-up": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M18 15V6H9M6.25 17.75 17 7"
                />
            </svg>
        ),
        "arrow-right-up-circle": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill={props.borderColor}
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10m-6-4H9v2h3.586l-5 5L9 16.414l5-5V15h2z"
                    clip-rule="evenodd"
                />
            </svg>
        ),
        "arrow-up": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="m6 10 6-6 6 6m-6-5v15"
                />
            </svg>
        ),
        automation: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M18.652 10H13l.915-5.946c.025-.165.038-.248.014-.281a.1.1 0 0 0-.09-.041c-.04.003-.095.067-.204.194l-8.409 9.81c-.072.084-.108.126-.109.162a.1.1 0 0 0 .037.08c.028.022.083.022.194.022H11l-.915 5.946c-.025.165-.038.248-.014.281a.1.1 0 0 0 .09.041c.04-.003.095-.067.204-.194l8.409-9.81c.072-.084.108-.126.108-.162a.1.1 0 0 0-.036-.08C18.818 10 18.763 10 18.652 10Z"
                />
            </svg>
        ),
        blog: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M9 3.5H5.3c-.28 0-.42 0-.527.054a.5.5 0 0 0-.218.219C4.5 3.88 4.5 4.02 4.5 4.3v15.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218c.107.055.247.055.527.055H9m0-17h9.7c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219c.055.107.055.247.055.527v15.4c0 .28 0 .42-.055.527a.5.5 0 0 1-.218.218c-.107.055-.247.055-.527.055H9m0-17v17M13 8h2.5M13 12h2.5"
                />
            </svg>
        ),
        cart: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2 3h1.322c.241 0 .361 0 .46.044a.5.5 0 0 1 .21.18c.06.088.08.207.12.444L4.5 6m0 0 1.389 8.332c.04.237.059.356.118.445a.5.5 0 0 0 .212.18c.098.043.218.043.459.043h12.144c.241 0 .361 0 .46-.044a.5.5 0 0 0 .21-.18c.06-.088.08-.207.12-.444l1.233-7.4c.053-.321.08-.481.034-.606a.5.5 0 0 0-.22-.26C20.543 6 20.38 6 20.056 6zM9 19a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                />
            </svg>
        ),
        "chat-bubble": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M3.802 4h16.4c.28 0 .42 0 .527.054a.5.5 0 0 1 .219.219c.054.107.054.247.054.527v12.4c0 .28 0 .42-.054.527a.5.5 0 0 1-.22.218c-.106.055-.246.055-.526.055h-8.2l-5 3v-3h-3.2c-.28 0-.42 0-.527-.055a.5.5 0 0 1-.219-.218c-.054-.107-.054-.247-.054-.527V4.8c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.218C3.382 4 3.522 4 3.802 4Z"
                />
            </svg>
        ),
        "chevron-double-up": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="m8 10 4-4 4 4m-8 7 4-4 4 4"
                />
            </svg>
        ),
        coins: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M9 16a6 6 0 1 1 5.5-8.402M21 14a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
                />
            </svg>
        ),
        cutlery: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M5 4v7l1.5 1.5v6.7c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C6.88 20 7.02 20 7.3 20h1.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218c.055-.107.055-.247.055-.527v-6.7L11 11V4M8 4v5M18.2 20c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C19 19.62 19 19.48 19 19.2V4c-.964 0-4.33 2.788-4.943 10.158-.024.284-.036.427.015.543a.5.5 0 0 0 .22.239c.111.06.258.06.55.06H16v4.2c0 .28 0 .42.055.527a.5.5 0 0 0 .218.218c.107.055.247.055.527.055z"
                />
            </svg>
        ),
        gift: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M5 12h-.2c-.28 0-.42 0-.527-.055a.5.5 0 0 1-.218-.218C4 11.62 4 11.48 4 11.2V8.8c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.218C4.38 8 4.52 8 4.8 8h14.4c.28 0 .42 0 .527.055a.5.5 0 0 1 .218.218C20 8.38 20 8.52 20 8.8v2.4c0 .28 0 .42-.055.527a.5.5 0 0 1-.218.218C19.62 12 19.48 12 19.2 12H19M5 12v7.2c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C5.38 20 5.52 20 5.8 20h12.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C19 19.62 19 19.48 19 19.2V12M5 12h14m-7-4V6.333M12 8h-1.667A3.333 3.333 0 0 1 7 4.667C7 3.747 7.746 3 8.667 3A3.333 3.333 0 0 1 12 6.333M12 8h1.667A3.333 3.333 0 0 0 17 4.667C17 3.747 16.254 3 15.333 3A3.333 3.333 0 0 0 12 6.333M12 8v12"
                />
            </svg>
        ),
        "line-chart": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="m19 11-1.434-1.434c-.198-.198-.297-.297-.412-.334a.5.5 0 0 0-.308 0c-.115.037-.214.136-.412.334l-3.868 3.868c-.198.198-.297.297-.412.334a.5.5 0 0 1-.309 0c-.114-.037-.213-.136-.41-.334l-1.37-1.368c-.197-.198-.296-.297-.41-.334a.5.5 0 0 0-.31 0c-.114.037-.213.136-.41.334L4.5 16.5M4 4v15.2c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C4.38 20 4.52 20 4.8 20H20"
                />
            </svg>
        ),
        loyalty: (
            <svg
                width={iconSize}
                height={iconSize}
                style={iconStyle}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 20H9C9 20 11.3916 20.9234 13 21C16.5463 21.1689 19.3633 19.5191 21.645 17.0098C22.1661 16.4367 22.1573 15.5716 21.6735 14.9668C21.0497 14.1871 19.8774 14.124 19.0953 14.7447C18.2335 15.4286 17.0898 16.1979 16 16.5C14.4947 16.9173 13 17 13 17C21 16.5 18 9.99985 7 14.5M7 13H3.8C3.51997 13 3.37996 13 3.273 13.0545C3.17892 13.1024 3.10243 13.1789 3.0545 13.273C3 13.38 3 13.52 3 13.8V20.2C3 20.48 3 20.62 3.0545 20.727C3.10243 20.8211 3.17892 20.8976 3.273 20.9455C3.37996 21 3.51997 21 3.8 21H7V13Z"
                    stroke={props.borderColor}
                    stroke-width="2"
                />
                <path
                    d="M14.2481 4.59784C14.2481 2.84984 12.5011 1.87915 10.9971 2.85015C9.49309 1.87815 7.74609 2.91915 7.74609 4.66815C7.74609 7.00015 10.9991 8.28015 10.9991 8.28015L11.8779 7.4695M11.8779 7.4695C12.487 9.29169 14.9991 10.2802 14.9991 10.2802C14.9991 10.2802 18.2481 9.00015 18.2481 6.66815C18.2481 4.92015 16.5011 3.87915 14.9971 4.85015C13.4931 3.87815 11.7461 4.91915 11.7461 6.66815C11.7461 6.95087 11.7939 7.21812 11.8779 7.4695Z"
                    stroke={props.borderColor}
                    stroke-width="2"
                />
            </svg>
        ),
        mail: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M3.288 8.644 12 13l8.712-4.356M3 5.8v12.4c0 .28 0 .42.055.527a.5.5 0 0 0 .218.218C3.38 19 3.52 19 3.8 19h16.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C21 18.62 21 18.48 21 18.2V5.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C20.62 5 20.48 5 20.2 5H3.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.218.219C3 5.38 3 5.52 3 5.8Z"
                />
            </svg>
        ),
        medal: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M8 15.25v5.456c0 .428 0 .643.09.772a.5.5 0 0 0 .337.208c.156.023.347-.073.73-.265l2.485-1.242c.131-.066.197-.098.266-.111a.5.5 0 0 1 .184 0c.069.012.135.045.266.11l2.484 1.243c.384.192.575.288.731.265a.5.5 0 0 0 .337-.208c.09-.13.09-.344.09-.772V15.25M19 9A7 7 0 1 1 5 9a7 7 0 0 1 14 0Z"
                />
            </svg>
        ),
        office: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-miterlimit="16"
                    stroke-width="2"
                    d="M4 19h10M4 19V4.8c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.218C4.38 4 4.52 4 4.8 4h8.4c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C14 4.38 14 4.52 14 4.8V8M4 19H2m18 0h-6m6 0V8.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C19.62 8 19.48 8 19.2 8H14m6 11h2m-8 0V8m-4 1H8m0 4h2"
                />
            </svg>
        ),
        page: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M9 7h6m-6 4h6m-6 4h2m-5.2 6h12.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C19 20.62 19 20.48 19 20.2V3.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.219C18.62 3 18.48 3 18.2 3H5.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.218.219C5 3.38 5 3.52 5 3.8v16.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C5.38 21 5.52 21 5.8 21Z"
                />
            </svg>
        ),
        party: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M14 2s.178 1.04 0 2c-.191 1.034-1 2-1 2m2.5 2.5 1-1m1.8-2.75.45-1.25M20 8l1-.5M18 11s.756.032 1.5.27c.67.215 1.5.73 1.5.73M8.937 6.937l8.126 8.126c.31.31.464.464.491.621a.5.5 0 0 1-.096.388c-.097.126-.305.191-.723.32L4.954 20.05c-.471.146-.706.22-.865.161a.5.5 0 0 1-.298-.297c-.058-.159.015-.394.161-.865L7.608 7.266c.13-.418.194-.627.32-.724a.5.5 0 0 1 .388-.096c.157.027.312.182.621.491Z"
                />
            </svg>
        ),
        plug: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M9 7H5.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.218.219C5 7.38 5 7.52 5 7.8v3.8c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C8.04 18 9.16 18 11.4 18h.6M9 7V3m0 4h6m0 0h3.2c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C19 7.38 19 7.52 19 7.8v3.8c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C15.96 18 14.84 18 12.6 18H12m3-11V3m-3 15v3"
                />
            </svg>
        ),
        prepaid: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M13.895 4.21a3.001 3.001 0 1 0-1.79 3.58m1.79-3.58a3 3 0 1 1-1.79 3.58m1.79-3.58c-1.403.556-2.184 2.134-1.79 3.58M7 20h2s2.392.923 4 1c3.546.169 6.363-1.48 8.645-3.99.521-.573.512-1.438.029-2.043-.624-.78-1.797-.843-2.579-.222-.861.684-2.005 1.453-3.095 1.755-1.505.417-3 .5-3 .5 8-.5 5-7-6-2.5M7 13H3.8c-.28 0-.42 0-.527.055a.5.5 0 0 0-.219.218C3 13.38 3 13.52 3 13.8v6.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C3.38 21 3.52 21 3.8 21H7z"
                />
            </svg>
        ),
        search: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="m20 20-3.95-3.95M18 11a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
            </svg>
        ),
        shine: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M12 2v6m0 8v6M8 12H2m14 0h6M7.5 7.5 6 6m10.5 1.5L18 6m-1.5 10.5L18 18M7.5 16.5 6 18"
                />
            </svg>
        ),
        "shopping-cart": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2 3h1.322c.241 0 .361 0 .46.044a.5.5 0 0 1 .21.18c.06.088.08.207.12.444L4.5 6m0 0 1.389 8.332c.04.237.059.356.118.445a.5.5 0 0 0 .212.18c.098.043.218.043.459.043h12.144c.241 0 .361 0 .46-.044a.5.5 0 0 0 .21-.18c.06-.088.08-.207.12-.444l1.233-7.4c.053-.321.08-.481.034-.606a.5.5 0 0 0-.22-.26C20.543 6 20.38 6 20.056 6zM9 19a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                />
            </svg>
        ),
        sleep: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M7.25 10.5s.458.3 1.375.3S10 10.5 10 10.5m4 0s.458.3 1.375.3 1.375-.3 1.375-.3m-6.75 5c1.386.533 2.614.533 4 0m7-3.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
            </svg>
        ),
        star: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M22 12c-6.944 0-10 3.056-10 10 0-6.944-3.056-10-10-10 6.944 0 10-3.056 10-10 0 6.944 3.056 10 10 10Z"
                    clip-rule="evenodd"
                />
            </svg>
        ),
        subscription: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M11 12h2.003M12 9.25l.808 1.638 1.807.262-1.307 1.275.308 1.8-1.616-.85-1.617.85.31-1.8-1.309-1.275 1.808-.262zM9.14 4.337 6.89 4.018c-.269-.038-.403-.057-.515-.02a.5.5 0 0 0-.245.178c-.07.095-.093.228-.14.496l-.393 2.24c-.025.142-.037.213-.067.274a.5.5 0 0 1-.106.147c-.05.047-.113.08-.24.148l-2.01 1.066c-.24.127-.36.191-.428.287a.5.5 0 0 0-.093.287c0 .118.059.24.178.484l1 2.044c.062.129.094.193.106.26a.5.5 0 0 1 0 .181 1 1 0 0 1-.107.261l-.999 2.044c-.12.244-.179.366-.178.484a.5.5 0 0 0 .093.287c.069.096.189.16.429.287l2.01 1.066c.126.067.19.1.24.148a.5.5 0 0 1 .105.146c.03.062.042.133.067.274l.393 2.24c.047.269.07.402.14.497a.5.5 0 0 0 .245.178c.112.037.246.018.515-.02l2.252-.319c.143-.02.214-.03.282-.02a.5.5 0 0 1 .172.055c.06.033.112.083.215.182l1.635 1.582c.195.189.293.283.404.319a.5.5 0 0 0 .303 0c.112-.036.21-.13.405-.32l1.635-1.58c.103-.1.155-.15.215-.183a.5.5 0 0 1 .172-.056c.068-.009.139.001.281.021l2.252.319c.27.038.404.057.515.02a.5.5 0 0 0 .245-.178c.07-.095.093-.228.14-.496l.393-2.24c.025-.142.038-.213.067-.274a.5.5 0 0 1 .106-.147c.05-.047.114-.08.24-.148l2.01-1.066c.24-.127.36-.191.428-.287a.5.5 0 0 0 .094-.287c0-.118-.06-.24-.178-.484l-1-2.044a1 1 0 0 1-.106-.26.5.5 0 0 1 0-.181c.012-.068.044-.132.107-.261l.999-2.044c.119-.244.178-.366.178-.484a.5.5 0 0 0-.094-.287c-.068-.096-.188-.16-.428-.287l-2.01-1.066c-.126-.067-.19-.1-.24-.148a.5.5 0 0 1-.106-.147c-.03-.061-.042-.132-.067-.273l-.393-2.24c-.047-.269-.07-.402-.14-.497a.5.5 0 0 0-.245-.178c-.111-.037-.246-.018-.515.02l-2.252.319c-.142.02-.213.03-.281.02a.5.5 0 0 1-.172-.055 1 1 0 0 1-.215-.182l-1.635-1.582c-.195-.189-.293-.283-.405-.319a.5.5 0 0 0-.303 0c-.111.036-.21.13-.404.32L9.81 4.118c-.103.1-.155.15-.215.183a.5.5 0 0 1-.172.056c-.068.009-.14-.001-.282-.021Z"
                />
            </svg>
        ),
        suitcase: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M8 7H3.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.219.219C3 7.38 3 7.52 3 7.8v11.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C3.38 20 3.52 20 3.8 20h16.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C21 19.62 21 19.48 21 19.2V7.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C20.62 7 20.48 7 20.2 7H16M8 7V3.8c0-.28 0-.42.055-.527a.5.5 0 0 1 .218-.219C8.38 3 8.52 3 8.8 3h6.4c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C16 3.38 16 3.52 16 3.8V7M8 7h8"
                />
            </svg>
        ),
        text: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M4 6V4h8m8 2V4h-8m0 0v16m0 0h-2m2 0h2"
                />
            </svg>
        ),
        things: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M17.444 17a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM4.655 7.405c.016-.09.024-.135.047-.157a.1.1 0 0 1 .076-.028c.033.002.068.032.137.09l4.883 4.097c.07.059.105.088.112.12a.1.1 0 0 1-.014.08c-.018.026-.06.042-.146.073l-5.99 2.18c-.085.031-.128.047-.159.038a.1.1 0 0 1-.062-.053c-.015-.029-.007-.074.01-.163zM14.487 4.523c.073-.27.109-.406.19-.495a.5.5 0 0 1 .267-.155c.117-.025.253.012.523.084l4.25 1.139c.27.072.406.109.495.189a.5.5 0 0 1 .155.268c.024.117-.012.252-.084.523l-1.139 4.25c-.072.27-.109.406-.189.495a.5.5 0 0 1-.268.154c-.117.025-.252-.011-.523-.084l-4.25-1.138c-.27-.073-.406-.11-.495-.19a.5.5 0 0 1-.154-.267c-.025-.117.011-.253.083-.523z"
                />
            </svg>
        ),
        tree: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M12 21v-6m0 0-2-2m2 2 3-3m5-1a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
                />
            </svg>
        ),
        touchpoints: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M17 18h1M4 16V4.8c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.218C4.38 4 4.52 4 4.8 4h14.4c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C20 4.38 20 4.52 20 4.8V9M4 16H2.8c-.28 0-.42 0-.527.055a.5.5 0 0 0-.219.218C2 16.38 2 16.52 2 16.8v2.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C2.38 20 2.52 20 2.8 20H13m-9-4h9m.8 5h7.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C22 20.62 22 20.48 22 20.2V9.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C21.62 9 21.48 9 21.2 9h-7.4c-.28 0-.42 0-.527.055a.5.5 0 0 0-.218.218C13 9.38 13 9.52 13 9.8v10.4c0 .28 0 .42.055.527a.5.5 0 0 0 .218.218c.107.055.247.055.527.055Z"
                />
            </svg>
        ),
        trophy: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="M12.004 17v-3M18 5h2.2c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C21 5.38 21 5.52 21 5.8V7a3 3 0 0 1-3 3M6 5H3.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.219.219C3 5.38 3 5.52 3 5.8V7a3 3 0 0 0 3 3m6 4a6 6 0 0 1-6-6V3.8c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.219C6.38 3 6.52 3 6.8 3h10.4c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C18 3.38 18 3.52 18 3.8V8a6 6 0 0 1-6 6Zm5 6.2v-2.4c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C16.62 17 16.48 17 16.2 17H7.8c-.28 0-.42 0-.527.055a.5.5 0 0 0-.218.218C7 17.38 7 17.52 7 17.8v2.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C7.38 21 7.52 21 7.8 21h8.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C17 20.62 17 20.48 17 20.2Z"
                />
            </svg>
        ),
        user: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.5 6.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM12 13c-3.875 0-6.682 2.63-7.374 6.13-.053.27-.08.406-.035.534a.53.53 0 0 0 .22.267c.116.069.267.069.568.069H18.62c.301 0 .452 0 .569-.069a.53.53 0 0 0 .22-.267c.044-.128.017-.263-.036-.534C18.682 15.63 15.875 13 12 13Z"
                />
            </svg>
        ),
        users: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14.274 13.5c3.2-1.502 7.37.38 8.134 5.644.04.276.06.414.013.537a.52.52 0 0 1-.22.254c-.115.065-.263.065-.56.065H17M10.5 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm9 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-6.858 13H2.358c-.296 0-.444 0-.559-.065a.52.52 0 0 1-.22-.254c-.047-.123-.028-.26.012-.535 1.19-8.195 10.628-8.195 11.818 0 .04.275.06.412.012.535a.52.52 0 0 1-.22.254c-.115.065-.263.065-.56.065Z"
                />
            </svg>
        ),
        "user-heart": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill={props.borderColor}
                    d="m4.626 19.13-.98-.194zm-.035.534.944-.328zm.22.267.505-.863zM14.5 6.5A2.5 2.5 0 0 1 12 9v2a4.5 4.5 0 0 0 4.5-4.5zM12 9a2.5 2.5 0 0 1-2.5-2.5h-2A4.5 4.5 0 0 0 12 11zM9.5 6.5A2.5 2.5 0 0 1 12 4V2a4.5 4.5 0 0 0-4.5 4.5zM12 4a2.5 2.5 0 0 1 2.5 2.5h2A4.5 4.5 0 0 0 12 2zM5.607 19.324C6.214 16.254 8.641 14 12 14v-2c-4.392 0-7.578 3.007-8.355 6.936zM5.38 21H12.5v-2H5.379zM12 14c.621 0 1.206.077 1.748.218l.504-1.936A9 9 0 0 0 12 12zm-8.355 4.936c-.023.116-.055.276-.069.422-.016.17-.015.391.07.635l1.89-.657a.6.6 0 0 1 .03.145c.004.04.001.064.001.063a4.54 4.54 0 0 1 .022-.128l.018-.092zM5.38 19h-.181l-.078-.004c-.007 0 .013 0 .05.01q.062.015.146.063l-1.011 1.725c.232.136.46.177.626.193.148.014.316.013.448.013zm-1.733.993c.115.33.357.625.659.801l1.01-1.726c.068.04.11.082.136.113.026.032.059.081.084.155z"
                />
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M21.253 17.69c0 2.332-3.252 3.613-3.252 3.613s-3.251-1.28-3.251-3.613c0-1.748 1.748-2.789 3.251-1.818 1.504-.97 3.252.07 3.252 1.818Z"
                />
            </svg>
        ),
        "video-camera": (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-width="2"
                    d="m15.5 9.75 5.268-2.634c.077-.039.116-.058.147-.053a.1.1 0 0 1 .067.041c.018.026.018.07.018.155v9.482c0 .086 0 .129-.018.154a.1.1 0 0 1-.067.042c-.031.005-.07-.014-.147-.053L15.5 14.25M3.8 19h10.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C15 18.62 15 18.48 15 18.2V5.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C14.62 5 14.48 5 14.2 5H3.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.219.219C3 5.38 3 5.52 3 5.8v12.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C3.38 19 3.52 19 3.8 19Z"
                />
            </svg>
        ),
        voucher: (
            <svg
                width={iconSize}
                height={iconSize}
                fill="none"
                viewBox="0 0 24 24"
                style={iconStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke={props.borderColor}
                    stroke-linecap="square"
                    stroke-width="2"
                    d="M15 8v.01M15 12v.01M15 16v.01m6-6.76V5.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C20.62 5 20.48 5 20.2 5H3.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.219.219C3 5.38 3 5.52 3 5.8v3.45c2.5 1 2.5 4.5 0 5.5v3.45c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C3.38 19 3.52 19 3.8 19h16.4c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C21 18.62 21 18.48 21 18.2v-3.45c-2.5-1-2.5-4.5 0-5.5Z"
                />
            </svg>
        ),
    }
    return (
        <div style={{ ...containerStyle, height: iconSize, width: iconSize }}>
            {icons[props.icon]}
        </div>
    )
}

Icon.defaultProps = {
    borderColor: "#111111",
    icon: "user",
    size: "base",
}

addPropertyControls(Icon, {
    borderColor: { type: ControlType.Color, title: "Border Color" },
    icon: {
        type: ControlType.Enum,
        title: "Icon",
        options: [
            "add",
            "api-connection",
            "arrow-left",
            "arrow-right",
            "arrow-right-up",
            "arrow-right-up-circle",
            "arrow-up",
            "automation",
            "blog",
            "cart",
            "chat-bubble",
            "chevron-double-up",
            "coins",
            "cutlery",
            "gift",
            "graph",
            "line-chart",
            "loyalty",
            "mail",
            "medal",
            "megaphone",
            "office",
            "page",
            "party",
            "plug",
            "prepaid",
            "referrals",
            "search",
            "shine",
            "shopping-cart",
            "sleep",
            "star",
            "subscription",
            "suitcase",
            "text",
            "things",
            "tree",
            "touchpoints",
            "trophy",
            "user",
            "users",
            "user-heart",
            "video-camera",
            "voucher",
        ],
        optionTitles: [
            "Add",
            "API connection",
            "Arrow left",
            "Arrow right",
            "Arrow right up",
            "Arrow right up circle",
            "Arrow up",
            "Automation",
            "Blog",
            "Cart",
            "Chart bar",
            "Chart line",
            "Chart pie",
            "Chat bubble",
            "Chevron double up",
            "Coins",
            "Cutlery",
            "Gift",
            "Graph",
            "Line chart",
            "Loyalty",
            "Mail",
            "Medal",
            "Megaphone",
            "Office",
            "Page",
            "Party",
            "Plug",
            "Prepaid",
            "Referrals",
            "Search",
            "Shine",
            "Shopping cart",
            "Sleep",
            "Star",
            "Subscription",
            "Suitcase",
            "Text",
            "Things",
            "Tree",
            "Touchpoints",
            "Trophy",
            "User",
            "Users",
            "User heart",
            "Video camera",
            "Voucher",
        ],
    },
    size: {
        type: ControlType.Enum,
        title: "Size",
        options: ["xss", "xs", "sm", "base", "xl", "2xl", "mega"],
        optionTitles: ["XXS", "XS", "Small", "Base", "XL", "2XL", "Mega"],
    },
})

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

const iconStyle = {
    display: "block",
}
